import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [
  {
    path: '*',
    name: 'all',
    redirect: '/404',
  },
  {
    path: '/:lang?',
    name: 'main',
    component: () => import('@/views/Main')
  },
  {
    path: '/sailor/su/:uuid/:lang?',
    name: 'main',
    component: () => import('@/views/Main')
  },
  {
    path: '/404',
    name: 'no-found',
    meta: {
      public: true,
      access: 'public'
    },
    component: () => import('@/views/NotFound')
  },

  {
    path: '/server-error',
    name: 'server-error',
    meta: {
      public: true,
    },
    component: () => import('@/views/Error')
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
	next()
})

export default router