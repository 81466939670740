<template lang="pug">
v-app(v-if="isLoadApp")
  v-main
    Header
    router-view
Loader(v-else)
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Header: () => import('@/components/Header'),
    Loader: () => import('@/views/Loader')
  },
  computed: {
    ...mapState({
      isLoadApp: state => state.app.isLoadApp,
      lang: state => state.app.lang
    })
  },
  watch: {
    '$i18n.locale': {
      handler () {
        document.title = `${ this.$t('seamansDocumentVerificationService') }`
      },
      immediate: true
    }
  },
  mounted () {
    const savedLanguage = localStorage.getItem('lang')
    this.$i18n.locale = savedLanguage || 'uk'
  }
}
</script>
