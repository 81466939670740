import Vue from 'vue'
import Vuex from 'vuex'

import App from './modules/app'
import Directory from './modules/directory'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app: App,
    directory: Directory
  }
})

export default store
