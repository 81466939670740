import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import router from '@/router'
import api from "./plugins/api"
import notify from '@ananasbear/notify'
import validate from '@/plugins/validation'
import i18n from '@/plugins/vue-i18n'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import moment from 'moment'
import VueSwal from 'vue-swal'

Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueSwal)

import '@/assets/sass/index.sass'

Vue.config.productionTip = false
Vue.prototype.$notification = notify
Vue.prototype.$api = api
Vue.prototype.$valid = validate
Vue.prototype.$moment = moment

moment.updateLocale('uk', {
  weekdaysMin: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд']
})

moment.updateLocale('en', {
  weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
})

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
