import i18n from '@/plugins/vue-i18n.js'

class VALIDATION {
  validation (field, type = [], text = null) {
    let errors = []
    field && type.forEach(item => {
      if (item === 'sameAs') {
        field.$params[item].eq = i18n.t(`sameAs.${ field.$params[item].eq }`)
      }
      field.$dirty && !field[item] && errors.push(
        !text ? i18n.t(`notify.validation.${ item }`, field, field.$params[item]) : text
      )
    })
    return errors
  }

  rules (_this, path) {
    try {
      let current = _this.$v

      for (let i = 0; i < path.split('.').length; i++) {
        current = current[path.split(".")[i]]
        if (!current) return false
      }
      return Object.entries(current).reduce((array, i) => {
        if (!i[0].includes('$')) array.push(i[0])
        return array
      }, [])
    } catch (e) {
      return null
    }
  }
}

const valid = new VALIDATION()
export default valid