import i18n from '@/plugins/vue-i18n'
import api from '@/plugins/api'
import { app } from '@/configs/endpoints'

export default {
  state: () => ({
    isLoadApp: true,
    isLoad: false,
    lang: localStorage.getItem('lang') || 'ua',
    sailorData: null,
    documentNotFound: false
  }),
  mutations: {
    SET_DATA_APP (state, data) {
      if (Object.entries(data)[0][0] === 'lang') {
        localStorage.setItem('lang', data.lang)
        i18n.locale = data.lang
      }
      Object.entries(data).forEach(([key, value]) => (state[key] = value))
    }
  },
  actions: {
    async checkValidDocument (context, body) {
      const requestTimeout =+ process.env.VUE_APP_REQUEST_TIMEOUT
      context.commit('SET_DATA_APP', { isLoad: true })
      context.commit('SET_DATA_APP', { sailorData: null })
      const response = await api.post(app.getDocument, { body })
      setTimeout(() => {
        if (response?.code === 200) context.commit('SET_DATA_APP', { sailorData: response.data })
        else if (response?.code === 404) context.commit('SET_DATA_APP', { documentNotFound: true })
        context.commit('SET_DATA_APP', { isLoad: false })
        return response
      }, requestTimeout)
    },

    downloadPDF (context, data) {
      context.commit('SET_DATA_APP', { isLoad: true })
      return api.post(app.downloadDocument, { body: data.body, responseType: 'blob' }).then(response => {
        const url = URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const delimiterPattern = /[\s!@#$%^&*()\-+=[\]{};':"|,.<>/?~]/g
        const currentName = delimiterPattern.test(data.params.number_document) 
        ? data.params.number_document.replace(delimiterPattern, '_')
        : data.params.number_document
        link.download = `${ currentName || data.body.type_document }.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        context.commit('SET_DATA_APP', { isLoad: false })
      })
    }
  }
}
