export default {
  documentInfo: {
    serviceName: 'Державна служба морського і внутрішнього водного транспорту та судноплавства України',
    documentStatus: 'Статус документа',
    on: 'на',
    documentStatusValue: {
      valid: 'Дійсний',
      invalid: 'Не дійсний'
    },
    nameOfEducationalInstitution: 'Назва навчального закладу',
    positionOrRestrictions: 'Посада/Обмеження',
    diploma_confirmation: 'Підтвердження диплома',
    qualification: 'Диплом',
    sti_certificate: 'Свідоцтво ЗПМ',
    certificate_of_competency: 'Сертифікат компетентності',
    endorsement_of_competency: 'Підтвердження сертифікату компетентності',
    ranker_certificate: 'Свідоцтво фахівця (рядовий склад)',
    certificate: 'Сертифікат',

    nameInUkr: 'ПІБ українською:',
    nameInEng: 'Прізвище та ім`я англійською:',
    sex: 'Стать:',
    dateOfBirth: 'Дата народження:',
    citizenship: 'Громадянство:',
    rank: 'Звання:',
    position: 'Посада:',
    dateOfIssueDocument: 'Дата видачі документа:',
    competenceStandardSTCWConvention: 'Стандарт компетентності згідно конвенції ПДНВ:',
    additionalData: 'Додаткові дані',
    function: 'Функція',
    level: 'Рівень',
    limitation: 'Обмеження',
    documentNumber: 'Номер документа:',
    expiryDate: 'Дата закінчення строку чинності:',
    agent: 'Уповноважена особа',
    placeOfIssue: 'Місце видачі:',
    formNumber: '№ бланку:',
    validUntil: 'Дійсний до',
    endDateOfCourse: 'Дата закінчення курсу:',
    maritimeAdministrationUkraine: 'Морською Адміністрацією України',
    successfullyCompletedCourse: 'Успішно закінчив схвалений курс підготовки:',
    certifyThat: 'Цим засвідчується, що',
    issueComment: 'Видане на підставі положень Міжнародної конвенції про підготовку, дипломування моряків та несення вахти 1978 року, з поправками (з урахуванням Манільських поправок).',
    currentPosition: 'Посада'
  },
  error: {
    notfound: 'Сторінку не знайдено',
    returnYouTo: 'Але можемо повернути вас на',
    main: 'головну',
    other: 'Виявлено помилку'
  },
  login: {
    title: 'Увійти',
    email: 'Електронна адреса',
    password: 'Пароль',
    button: 'Увійти',
    orsign: 'Або увійти за допомогою',
    forgot: 'Забули пароль?',
    noaccount: 'Не маєте облікового запису?',
    create: 'Створити тут',
    error: 'Введено неправильний логін / пароль'
  },
// Vuetify components translations
  $vuetify: {
    badge: 'Значок',
    close: 'Закрити',
    dataIterator: {
      noResultsText: 'Відповідних записів не знайдено',
      loadingText: 'Завантаження елементів...'
    },
    dataTable: {
      itemsPerPageText: 'Рядки на сторінці:',
      ariaLabel: {
        sortDescending: 'Відсортовано за спаданням.',
        sortAscending: 'Відсортовано за зростанням.',
        sortNone: 'Не відсортовано.',
        activateNone: 'Активуйте, щоб прибрати сортування.',
        activateDescending: 'Активуйте, щоб сортувати за спаданням.',
        activateAscending: 'Активуйте, щоб сортувати за зростанням.'
      },
      sortBy: 'Сортувати за'
    },
    dataFooter: {
      itemsPerPageText: 'Елементів на сторінці:',
      itemsPerPageAll: 'Усі',
      nextPage: 'Наступна сторінка',
      prevPage: 'Попередня сторінка',
      firstPage: 'Перша сторінка',
      lastPage: 'Остання сторінка',
      pageText: '{0}-{1} of {2}'
    },
    datePicker: {
      itemsSelected: '{0} вибрано',
      nextMonthAriaLabel: 'Наступний місяць',
      nextYearAriaLabel: 'Наступний рік',
      prevMonthAriaLabel: 'Попередній місяць',
      prevYearAriaLabel: 'Попередній рік',
      dateStart: 'Дата початку',
      dateEnd: 'Дата кінця'
    },
    noDataText: 'Дані відсутні',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}'
      }
    },
    calendar: {
      moreEvents: '{0} більше'
    },
    fileInput: {
      counter: '{0} файли',
      counterSize: '{0} файлів ({1} загалом)'
    },
    timePicker: {
      am: 'AM',
      pm: 'PM'
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Навігація пагінацією',
        next: 'Наступна сторінка',
        previous: 'Попередня сторінка',
        page: 'Перейти на сторінку {0}',
        currentPage: 'Поточна сторінка, Сторінка {0}'
      }
    }
  },
  seamansDocumentVerificationService: 'Сервіс верифікації документів моряка',
  documentDisplayedHere: 'Тут буде відображено ваш документ',
  mainInfo: 'Загальна інформація',
  welcome: 'Вхід до застосунку',
  welcome2: 'Щоб розпочати роботу у застосунку, виконайте вхід у свій акаунт.',
  signBy2F: 'Введіть код двухфакторної авторизації',
  createBy2F: 'Відскануйте QR-код та введіть код двофакторної авторизації',
  code: 'Код',
  // project: 'Проект',
  btn: {
    reset: 'Скинути',
    apply: 'Застосувати',
    add: 'Додати',
    back: 'Назад',
    sign: 'Увійти',
    cancel: 'Відхилити',
    save: 'Зберегти',
    detail: 'Деталі',
    edit: 'Редагувати',
    saveAndNext: 'Зберегти та продовжити',
    clear: 'Очистити',
    find: 'Шукати',
    searchByDocumentNum: 'Пошук за номером документа',
    searchByVerificationNum: 'Пошук за верифікаційним номером',
    findDocument: 'Знайти документ'
  },
  ship: {
    ship: 'Судно',
    all: 'Усі судна',
    add: 'Додати судно',
    newItem: 'Нове судно',
    name: 'Назва',
    port: 'Порт приписки',
    sailorArea: 'Район плавання',
    techData: 'Технічні дані',
    techInspection: 'Технічний огляд',
    cin: 'CIN',
    accountingStatus: 'Обліковий статус судна',
    currentStatus: 'Поточний статус огляду',
    classSymbol: 'Символ класу судна',
    group: 'Група судна',
    viewShipCard: 'Переглянути картку судна',
    individualSavingEquipment: 'Індивідуальні рятувальні засоби',
    lifeBuoys: 'Рятувальні круги',
    withSavingLine: 'з рятувальним лінем',
    withSelfIgnitingBuoy: 'з самозапальним буйком',
    withTowerFlagLamp: 'з вишкою із прапорцем і ліхтарем',
    otherSavingEquipment: 'Інші рятувальні засоби',
    hydroThermalSuits: 'Гідротермокостюми',
    thermalProtection: 'Теплозахисні засоби',
    insureBelts: 'Страхувальні пояси',
    insureLine: 'Страхувальні ліні',
    lifeJackets: 'Рятувальні жилети',
    forAdults: 'Для дорослих',
    forKids: 'Для дітей',
    identificationNumber: 'Ідентифікаційний номер (CIN)',
    certificateNumber: 'Номер сертифіката',
    registrationNumber: 'Регістровий номер',
    regNumber: 'Рег. номер',
    buildNumber: 'Будівельний номер',
    shipCommercial: 'Комерційне судно',
    shipClass: 'Клас судна',
    fireProtection: 'Протипожежне забезпечення',
    otherEquipment: 'Інше забезпечення',
    registeredShips: 'Зареестровані судна',
    radioEquipment: 'Радіообладнання',
    navigationEquipment: 'Навігаційне обладнання',
    inspectionsList: 'Історія оглядів',
    inspectionDate: 'Дата огляду',
    carriedOut: 'Проведено',
    assigned: 'Призначено',
    inspectionType: 'Тип огляду',
    laboratory: 'Лабораторія',
    period: 'Період'
  },
  typeOfTheDocuments: {
    diploma: 'Професійний диплом',
    confirmation: 'Підтвердження професійного диплома',
    specialistCertificate: 'Свідоцтво фахівця',
    ntzCertificate: 'НТЗ сертифікат',
    stiCertificate: 'Свідоцтво ЗПМ',
    medicalCertificate: 'Медичний сертифікат',
    seamanRecordBook: 'Послужна книжка моряка',
    certificateOfCompetency: 'Сертифікат компетентності',
    endorsementOfCompetency: 'Підтвердження сертифікату компетентності',
    rankerCertificate: 'Свідоцтво фахівця (рядовий склад)'
  },
  project: {
    checkDocument: 'Перевірити документ',
    maritimeDocuments: 'Кваліфікаційні документи',
    dateOfIssue: 'Дата видачі',
    documentType: 'Тип документа',
    verificationNumber: 'Номер верифікації',
    documentNumber: 'Номер документа',
    searchDocument: 'Пошук документа',
    main: 'Основні дані',
    project: 'Проект',
    number: 'Номер',
    all: 'Усі проекти',
    add: 'Додати проект',
    newItem: 'Створити новий проект',
    select: 'Обрати з існуючих проектів',
    name: 'Прізвище (українською)',
    masts: 'Вітрильне озброєння',
    author: 'Автор проекту',
    // type: 'Тип судна',
    purpose: 'Призначення судна',
    view: 'Вид',
    unsinkable: 'Непотоплюваність забезпечується',
    length: 'Довжина',
    width: 'Ширина',
    height: 'Висота',
    size: 'Розмір',
    freeboardHeight: 'Висота надводного борту',
    submersion: 'Осадка',
    passengerCapacity: 'Пасажиромісткість',
    speed: 'Швидкість',
    maxLoadMainEngine: 'Максимальна потужність головних двигунів',
    countWaterproofBulkheads: 'Кількість водонепроникних перегородок',
    cockpit: 'Кокпіт',
    diameterDrains: 'Діаметр стоків',
    countMasts: 'Кількість мачт',
    heightMasts: 'Висота матч',
    hasUnsinkableInfo: 'остійність та непотоплюваність ',
    unsinkableInfo: 'Остійність та непотоплюваність ',
    unsinkableVolume: 'Об`єм',
    mechanism: 'Механізм',
    mooringType: 'Тип швартового механізму',
    hasDrogue: 'Плавучий якір',
    exist: 'Має',
    notExist: 'Не має',
    hasLeer: 'Леєр рятувальний по периметру корпусу',
    hasDrainageSystem: 'Осушувальна система',
    hasCompressedAirSystem: 'Система стисненого повітря',
    hasAirGuards: 'повітрозберігателі',
    hasDrinkWater: 'Цистерни запасу питної води',
    hasStationaryFireExtinguishing: 'Стаціонарні системи пожежогасіння',
    hasDomesticLiquefiedGasSystem: 'Система побутового скрапленого газу',
    hasWaterFireSystem: 'Водопожежна система',
    hasFireCarbonDioxideSystem: 'Система вуглекислотного пожежогасіння машинного приміщення',
    hasFireAerosolSystem: 'Аерозольна система пожежогасіння',
    manufacturerType: 'Спосіб виготовлення',
    MACS: 'Засоби активного управління судном (ЗАКС)',
    steeringType: 'Вид рульового управління',
    steeringGear: 'Рульовий привід',
    fencing: 'Огородження на відкритих палубах',
    closingHoles: 'Закриття отворів в корпусі',
    anchorType: 'Тип якоря',
    mechanisms: 'Механізми',
    device: 'Пристрої',
    steerage: 'Рульовий пристрій',
    anchorDevice: 'Якірний пристрій',
    anchorProviding: 'Якірне забезпечення',
    anchorMechanismType: 'Тип якірного механізму',
    mooringDevice: 'Швартовий та буксирний пристрої',
    safety: 'Безпека',
    safetyAndEtc: 'Безпека та інше',
    systems: 'Системи',
    air: 'Повітря',
    firefighting: 'Пожежогасіння',
    carryCapacity: 'Вантажопідйомність',
    shipView: 'Вид судна',
    carcase: 'Корпус',
    ballastWeight: 'Вага баласту',
    grossTonnage: 'Валовий тоннаж',
    crewCapacity: 'Екіпаж',
    ballast: 'Баласт',
    sailType: 'Тип вітрильного озброєння',
    countCarcass: 'Кількість корпусів',
    carcassType: 'Тип корпуса',
    carcass: 'Корпус',
    shipMaterial: 'Матеріал корпусу',
    superstructureMaterial: 'Матеріал надбудови',
    material: 'Матеріал',
    unsinkableTitle: 'Остійність. Непотоплюваність. Вантажна марка',
    freightBrandType: 'Вантажна марка',
    equipment: 'Обладнання',
    addEngine: 'Додати двигун',
    mainEngine: 'Головний двигун',
    engine: 'Двигун',
    dieselGenerator: 'Дизельний генератор',
    additionalEngine: 'Допоміжний двигун',
    type: 'Тип',
    brand: 'Марка',
    engineModel: 'Модель двигуна',
    characteristic: 'Характеристика',
    installationBrand: 'Виробник',
    buildPlace: 'Місце виготовлення',
    buildYear: 'Рік виготовлення',
    fuelType: 'Вид палива',
    fuelTankVolume: 'Об’єм паливного бака',
    power: 'Потужність',
    equivalent: 'Еквівалент',
    secondaryEngine: 'Допоміжний двигун',
    classC: 'Класс',
    squareMainSails: 'Площа основних вітрил',
    squareStormSails: 'Площа штормових вітрил',
    propeller: 'Рушій',
    count: 'Кількість',
    countBlade: 'Кількість лопатей',
    additionallyInfo: 'Додаткова інформація',
    electricalEquipment: 'Електрообладнання',
    energySource: 'Джерело енергії',
    electricalEquipmentType: 'Тип генератора(ів) / акумулятора(ів):',
    powerType: 'Рід струму',
    voltage: 'Напруга',
    anchor: 'Якір',
    weight: 'Вага',
    tensileStrength: 'Розривне зусилля',
    peopleHaveSafetyItems: 'Кількість людей, для яких передбачені рятувальні засоби',
    mooringTitle: 'Швартовний і буксирний пристрої',
    mooringRopes: 'Швартові троси',
    diameter: 'Діаметр',
    towingRopes: 'Буксирні троси',
    providing: 'Забезпечення',
    typePump: 'Тип насосів',
    typeCompressors: 'Тип компресорів',
    airKeepers: 'Повітрязберегачі',
    capacity: 'Місткість',
    pressure: 'Тиск',
    balloonsCarbonDioxide: 'Балони з вуглекислим газом',
    countBalloons: 'Кількість балонів',
    capacityBalloons: 'Місткість балонів',
    location: 'Розташування',
    typeAerosolGenerator: 'Тип генератора аерозоля',
    protectedPremises: 'Приміщення, що захищаються',
    emergencyTiller: 'Аварійний румпель',
    stormSetOfSail: 'Штормовий комплект вітрил',
    oars: 'Весла',
    reflectors: 'Відбивачі',
    hooksForRepulsionAndAttraction: 'Крюки для відштовхування і притягання',
    firstAidKit: 'Аптечка',
    ladle: 'Черпак',
    powerConverterModel: 'Ковертор живлення (модель)',
    knife: 'Ніж',
    repairKit: 'Ремкомплект',
    footStock: 'Футшток',
    addEquipment: 'Додати засіб',
    addRaft: 'Додати пліт',
    raft: 'Пліт',
    otherProviding: 'Інше забезпечення',
    savingEquipment: 'Рятувальні засоби',
    savingRafts: 'Рятувальні плоти',
    manufacturer: 'Виготовлювач',
    yearOfManufacture: 'Рік виготовлення',
    serialNumber: 'Серійний №',
    notApplicable: 'Не застосовується',
    fireProtection: 'Протипожежне забезпечення',
    fireExtinguishers: 'Переносні вогнегасники',
    curtainExtinguishing: 'Покривало для гасіння пожежі (кошма)',
    ax: 'Сокира',
    firePlan: 'Пожежний план',
    firePump: 'Пожежний насос',
    fireBucketWithTow: 'Відро пожежне з линвою',
    fireHose: 'Пожежний рукав',
    stermWhite: 'Кормовий білий',
    distance: 'Дальність',
    selectProject: 'Обрати проект',
    addDevice: 'Додати пристрій',
    signalingMeans: 'Сигнальні засоби',
    signalingMeansName: 'Найменування (сигнальний засіб)',
    signalRecognitionLightsAndDaySignals: 'Сигнально-розпізнавальні ліхтарі та денні сигнали',
    grenadeOrRocket: 'Граната або ракета звукова',
    oneStarRocketGreen: 'Однозіркова ракета зелена',
    oneStarRocketRed: 'Однозіркова ракета червона',
    parachuteRocket: 'Парашутна ракета (суднова) біди',
    clothRegisterVessel: 'Полотнище 1м х 0,8м з Регістровим №, назвою судна, № причалу',
    flagWaving: 'Прапор-відмашка червоний',
    signalBulletGreen: 'Сигнальна куля зелена',
    signalBulletBlack: 'Сигнальна куля чорна',
    signalConeYellow: 'Сигнальний конус жовтий',
    signalConeBlack: 'Сигнальний конус чорний',
    signalFlag: 'Сигнальний прапор',
    signalRhombus: 'Сигнальний ромб чорний',
    signalCylinder: 'Сигнальний циліндр білий',
    falshfeyerWhite: 'Фальшфейєр білий',
    falshfeyerRed: 'Фальшфейєр червоний',
    redPennant: 'Червоний вимпел',
    bell: 'Дзвін',
    electricSignalMobileType: 'Електричний сигнальний пристрій авто-мобільного типу',
    electricAirSiren: 'Електрично-повітряна сирена',
    plate: 'Плита',
    whistle: 'Свисток',
    lightWave: 'Світлова відмашка',
    lightImpulseSwing: 'Світлоімпульсна відмашка',
    sideLeftSideRed: 'Бортовий лівого борту червоний',
    sideLeftSideGreen: 'Бортовий правого борту зелений',
    spareLightBulbs: 'Запасні лампочки',
    spareLight: 'Запасні ліхтарі',
    combinedLightGreenAndRed: 'Комбінований ліхтар зелений з червоним',
    setsLimited: 'Комплекти «Не можу управлятися» та «Обмежений у можливості маневрування»',
    sternWhite: 'Кормовий білий',
    circular: 'Круговий',
    circularWhite: 'Круговий білий',
    circularGreen: 'Круговий зелений',
    circularYellow: 'Круговий проблисковий жовтий',
    circularRed: 'Круговий червоний',
    combinedTricolor360: 'Об’єднаний трикольоровий з загальним кутом освітлення 360°',
    parkingWhiteBoard: 'Стоянкові білий бортові',
    parkingWhiteRoad: 'Стоянкові білий круговий',
    topWhite: 'Топовий білий',
    dayTimeSignal: 'Денні сигнали',
    soundSignaling: 'Звукові сигнальні засоби',
    signalAndFlashingLight: 'Сигнально-проблискові ліхтарі',
    pyrotechnicSignalingDevices: 'Піротехнічні сигнальні засоби',
    signalRecognitionLightsAndDaySignal: 'Сигнально-розпізнавальні ліхтарі та денні сигнали',
    visibilityMilesShort: 'дальність видимості в милях (при довжині судна < 12 м)',
    visibilityMilesLong: 'дальність видимості в милях (при довжині судна 12- 20м, > 20м)',
    visibilityKilometres: 'дальність видимості в кілометрах (для суден ВВШ)',
    navigationEquipment: 'Навігацийне обладнання',
    basicMagneticCompass: 'Магнітний компас основний',
    spareMagneticCompass: 'Магнітний компас запасний',
    gyrocompass: 'Гірокомпас',
    radarStation: 'Радіолокаційна станція',
    GPS: 'Прийомоіндикатор системи радіонавігації (GPS)',
    ECNIS: 'Електронна картографична навигаційно-інформаційна система (ЕКНІС)',
    AIS: 'Автоматична ідентифікаційна система (АІС)',
    radarReflector: 'Радіолокаційний відбивач',
    navigationTool: 'Штурманський інструмент',
    chronometer: 'Хронометр',
    marineClock: 'Морский годиник',
    chipLog: 'Лаг',
    echoSounder: 'Ехолот',
    shipInclinometer: 'Кренометр',
    aneroidBarometer: 'Барометр-анероїд',
    anemometer: 'Анемометр',
    prismaticBinoculars: 'Бінокль призматичний',
    stopwatch: 'Секундомір',
    simpleManualLot: 'Лот простий (ручний)',
    radioEquipment: 'Радіообладнання',
    decimeterWavesRadioUSW: 'УКХ – радіоустановка дециметрових хвиль (300,025 -336,02 Мгц)',
    portableDecimeterWavesRadioUSW: 'Портативна УКХ радіостанція дециметрових хвиль (300,025 -336,02 Мгц)',
    radioUSWDigitalSelectiveCalling: 'УКХ радіоустановка з ЦИВ (156,3 - 156,875 Мгц)',
    radioIWSWDigitalSelectiveCalling: 'ПХ/КХ – радіоустановка з ЦИВ',
    stationINMARSATC: 'Суднова земна станція ІНМАРСАТ-С',
    receiverNAVTEX: 'Приймач служби НАВТЕКС',
    radioBeaconCospasSarsat: 'Супутниковий АРБ системи КОСПАС-САРСАТ с пристроєм самовідділення',
    shipRadarRespondent: 'Радіолокаційний відповідач судновий',
    twoWayCommunicationUSW: 'УКХ – апаратура двостороннього зв\'язку',
    mobileCommunication: 'Мобільний зв’язок',
    bracingView: "Вид кріплення",
    viewProjectCard: 'Переглянути картку проекту',
    attachmentAnchorType: 'Тип кріплення якоря',
    projectName: 'Назва проекту',
    cargoMark: 'Вантажна марка',
    collectiveSavingEquipment: 'Колективні рятувальні засоби',
    installationType: 'Тип установки'
  },
  profile: {
    profile: 'Профіль',
    name: 'Ім\'я',
    key: 'Ключ U2F',
    security: 'Безпека'
  },
  selectTheIssueDate: 'Виберіть дату видачі',
  enterTheIssueDate: 'Введіть дату видачі',
  enterTheDocumentName: 'Введіть прізвище українською',
  enterTheDocumentNumber: 'Введіть номер документа',
  enterVerificationNumber: 'Введіть верифікаційний номер',
  selectTheDocumentType: 'Виберіть тип документа',
  logoTitle: 'Верифікація документів моряка',
  main: 'Головна',
  settings: 'Налаштування',
  authTOTP: 'Двухфакторна авторизація TOTP',
  authU2F: 'Двухфакторна авторизація U2F',
  notes: 'Примітки',
  no: 'Немає',
  noNeed: 'Не передбачено проектом',
  unit: 'Одиниця виміру',
  size: 'Розмір',
  units: {
    year: 'рік',
    ton: 'т',
    kg: 'кг',
    people: 'чоловік',
    pieces: 'шт',
    meter: 'м',
    cubicMeter: 'м³',
    knot: 'узел',
    mm: 'мм',
    speed: 'км/ч',
    kw: 'кВт',
    power: 'В',
    horsepower: 'к.с',
    miles: 'Милі',
    squareMeter: 'м²',
    volt: 'В',
    mile: 'Мілі',
    kilometre: 'Км',
    litre: 'л',
    megaPascal: 'МПа',
    kilonewton: 'кН'
  },
  notFindData: 'Нічого не знайдено',
  chooseProject: 'Оберіть поект, що відповідає параметрам судна.',
  orAddNew: 'або створіть новий',
  action: 'Дія',
  fullName: 'ПІБ',
  region: 'Область',
  shipCount: 'Кількість суден',
  createAt: 'Дата створення',
  legalType: 'Тип',
  individual: 'Фіз. особа',
  // owner: 'Судоавласник',
  menu: {
    rsu: 'RSU',
    addingShip: 'Додавання судна',
    editShip: 'Редагування судна',
    shipCard: 'Картка судна',
    projectCard: 'Картка проекту',
    addingProject: 'Додавання проекту',
    editProject: 'Редагування проекту',
    addingOwner: 'Додавання судовласника',

    owners: 'Судовласники',
    ownerCard: 'Картка судовласника',
    editOwner: 'Редагування судовласника',
    ships: 'Маломірні судна',
    projects: 'Реєстр проектів',
    settings: 'Налаштування'
  },
  owner: {
    add: 'Додати судовласника',
    adding: 'Додавання судовласника',
    typeOwnership: 'Форма власності',
    owner: 'Судовласник',
    passport: 'Серія та номер паспорту',
    passportIssuedBy: 'Ким видан',
    passportIssuedDate: 'Дата видачі',
    itn: 'Ідентифікаційний код (ІНН)',
    phone: 'Номер телефону',
    email: 'E-mail',
    index: 'Індекс',
    city: 'Місто',
    region: 'Регіон',
    country: 'Країна',
    countryBirth: 'Країна народження',
    citizenship: 'Громадянство',
    street: 'Вулиця',
    house: 'Дім',
    flat: 'Квартира',
    address: 'Адреса реєстрації',
    viewProfile: 'Переглянути профіль судновласника',
    lastNameUK: 'Прізвище Українською',
    firstNameUK: 'Ім\'я Українською',
    middleNameUK: 'По-батькові Українською',
    lastNameEN: 'Прізвище Англійською',
    firstNameEN: 'Ім\'я Англійською',
    organizationName: 'Назва організації',
    zkpo: 'Код ЄДРПОУ',
    registrationAddress: 'Адреса реєстрації',
    resident_address: 'Адреса фактичного проживання',
    isAddressResidentEqualRegister: 'Адреса фактичного проживання дорівнює адресі реєстрації'
  },
  status: 'Статус',
  statuses: {
    inProgress: 'В роботі',
    accepted: 'Прийнято у виконання',
    completed: 'Завершено',
    failed: 'Скасовано',
    opened: 'Відкрито',
    archived: 'Архів',
    reopened: 'Відкрито повторно',
    rejected: 'Відхилено'
  },
  statement: {
    statement: 'Заявка',
    statements: 'Заявки',
    sentStatements: 'Подані заявки',
    type: 'Тип заявки',
    photo: 'Фото огляду',
    dateRegister: 'Дата реєстрації',
    inputNumber: 'Вхідний номер',
    dateStart: 'Дата надходження',
    typeInspection: 'Вид та причини огляду',
    currentStatus: 'Поточний статус заявки',
    approve: 'Прийняти у виконання',
    startView: 'Почати огляд',
    reject: 'Відхилити заявку',
    toProcess: 'На доопрацювання'
  },
  documents: 'Документи',
  notify: {
    validation: {
      NUMBER_REGEX: 'Значення не відповідає допустимому',
      LANG_REGEX: 'Значення не відповідає допустимому',
      required: 'Обов\'язкове поле',
      minLength: 'Недостатня довжина',
      maxLength: 'Занадто велика довжина'
    },
    success: 'Виконано успішно',
    warning: 'Зверніть увагу',
    error: 'Помилка виконання',
    info: 'Повідомлення',
    required: 'Обов\'язкове поле',
    invalidFormat: 'Значення не відповідає допустимому',
    invalidLength: 'Довжина повинна бути {length}',
    shipAdded: 'Судно додано',
    shipAddingNow: 'Судно додається, зачекайте',
    shipNotAdded: 'Судно не додано, перевірте дані або мережу інтернет',
    projectAdded: 'Проект додано',
    projectEdited: 'Проект змінено',
    projectEditError: 'Редагувати проект неможливо. Вже є судно пов\'язане с цим проектом',
    projectAddingNow: 'Проект додається, зачекайте',
    projectNotAdded: 'Проект не додано, перевірте дані або мережу інтернет',
    serverError: 'Помилка серверу (500)',
    projectLoadError: 'Помилка завантаження даних проекту, спробуйте пізніше'
  },
  yes: 'Так',
  other: 'інших',
  SRU: 'SRU',
  UA: 'UA',
  available: 'Наявне',
  notAvailable: 'Не наявне'
}
